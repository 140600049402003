<template>
  <!-- index START -->
  <div class="index">

    <!-- TEST ----- qrcode:1st-step START -->
    <input
      type="text"
      class="test-input-terminal"
      :placeholder="'QR Code'"
      v-if="activeStep === 1 && showQrcodeTest"
      v-model="qrcodeTest"
      v-on:keyup.enter="onEnter"
    />
    <!-- TEST ----- qrcode:1st-step END -->

    <!-- qrcode:1st-step START -->
    <transition
      name="step-fade"
      mode="out-in"
    >
      <qrcode
        :class="[
          { 'hide' : loginError }
        ]"
        v-if="activeStep === 1"
        :resetStoreCart="resetStoreCart"
        :step="activeStep"
        :loginError="loginError"
        :box="box"
      />
    </transition>
    <!-- qrcode:1st-step END -->

    <!-- pin:2nd-step START -->
    <transition
      name="step-fade"
      mode="out-in"
    >
      <pin
        v-if="activeStep === 2"
        :resetStoreCart="resetStoreCart"
        :step="activeStep"
        :user="user.user"
        :forward="moveForward"
        :changeColor="true"
      />
    </transition>
    <!-- pin:2nd-step END -->

  </div>
  <!-- index END -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'cart-app-payment',
  components: {
    Qrcode: () => import('./Qrcode.vue'),
    Pin: () => import('./Pin.vue'),
  },
  data() {
    return {
      /**
       * box (token)
       */
      user: null,
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',

      /**
       * Steps
       */
      activeStep: 1,

      /**
       * QR Code
       */
      showQrcodeTest: false,
      qrcode: '',
      qrcodeTest: '',
      loginError: false,

      /**
       * Scan
       */
      articleEAN: '',
      articleEANTest: '',

      /**
       * Dialog
       */
      dialogVisible: false,
      dialogTitle: this.$t('terminal.index.dialog.title'),
      dialogHeadline: this.$t('terminal.index.dialog.headline'),
      dialogDescription: this.$t('terminal.index.dialog.description'),
      dialogContentType: 'manual',
    };
  },
  async created() {
    // set token
    if (this.boxToken === 'undefined' && this.boxLang === 'undefined' && localStorage.getItem('boxToken') && localStorage.getItem('boxLang')) {
      this.boxToken = localStorage.getItem('boxToken');
      this.boxLang = localStorage.getItem('boxLang');
    }

    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();
    }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    async FETCH_BOX() {
      await this.$store.dispatch('setBox', {
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.box;
    },

    onEnter() {
      // 6F0COFHJTMQG2DO4LXNUGAAFM6MI0SEEJ8FQMBBE5L3G6CT0GKBDHSTJF0FF2JHKEXO4
      // P43LCL56XS32DJBRU4F2P35C769QCJRSBLMXPID3XIBVVCEIIBPPLJ1H8EU7
      if (this.box && this.qrcodeTest && this.boxLang) {
        // login throught terminal
        // this.loginTerminal(this.box.id, this.qrcodeTest, this.boxLang);
        this.loginTerminalSession(this.qrcodeTest);
      }
    },

    keyDownHandler(e) {
      // 4001724819004 // 4001686315354 // 2704493005006
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      if (this.qrcodeTest === '' && this.activeStep === 1) {
        this.qrcode += char;

        // login throught terminal
        if (e.keyCode === 13 && this.box && this.qrcode && this.boxLang) {
          // this.loginTerminal(this.box.id, this.qrcode, this.boxLang);
          this.loginTerminalSession(this.qrcode);
        }
      }
    },

    loginTerminalSession(qrCode) {
      const formData = new FormData();
      formData.append('qrCode', qrCode);
      this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/loginTerminalSession`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            const user = response.data;
            const auth = user.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', auth);
            localStorage.setItem('auth', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.user = user;
            this.$store.commit('SET_USER', user);
            localStorage.setItem('user', JSON.stringify(user));

            // move forward
            this.moveForward(this.activeStep);

            // clear input
            setTimeout(() => {
              this.qrcode = '';
              this.qrcodeTest = '';
            }, 750);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 3500);
          }
        })
        .catch((error) => {
          this.loginError = true;
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            console.clear();

            this.errorStatus = error.response.data;
            this.loadingVisible = false;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });

            // reset login error variable
            setTimeout(() => {
              this.loginError = false;
            }, 3000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 3500);
          }
        });
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },

    moveForward(step, done) {
      this.activeStep = step + 1;
      if (step === 1) {
        localStorage.setItem('qrcodeActive', true);
        this.$router.push('/cart/summary');
      }
      if (done === true) {
        this.$router.push({
          path: '/cart/checkout',
          query: {
            paymentCategoryID: this.$route.query.paymentCategoryID,
            paymentCategoryName: this.$route.query.paymentCategoryName,
          },
        });
        // this.$router.push('/cart/summary');
      }
    },

    dialogOpen() {
      this.dialogVisible = true;
    },

    dialogClose() {
      this.dialogVisible = false;
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    scrollTo(target) {
      const container = this.$el.querySelector(target);
      container.scrollTop = container.scrollHeight;
    },

    success() {
      this.$notify({
        message: this.$t('cart.product.success'),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 3000,
      });
    },

    error(message) {
      if (message) {
        this.$notify({
          message: this.$t(`${message}`),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      } else {
        this.$notify({
          message: this.$t('cart.product.failed'),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      }
    },
  },
  mounted() {
    // if pin param
    if (Number(this.$route.query.activeStep) === 2) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.user = user;
      this.activeStep = 2;
    }
    if (localStorage.getItem('showQrcodeTest') && localStorage.getItem('showQrcodeTest') === 'true') {
      this.showQrcodeTest = true;
    } else {
      this.showQrcodeTest = false;
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
};
</script>
